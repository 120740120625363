import { useCreateForm } from "@formulier/react";

import { useUnknownDevices } from "~/components/devices/fetchers";
import { useActiveDevice } from "~/components/devices/hooks";

import { defineMessages, FormattedMessage } from "~/intl";

import { useCurrentUser } from "~/providers/current-user-provider";

import { Device } from "~/services/api-schemas";

import { FieldInput, FieldItem, FieldLabel, FormSubmitContext } from "~/shared/components/form";
import { Heading } from "~/shared/components/heading";
import {
  SelectInput,
  SelectInputButton,
  SelectInputMenu,
  SelectInputOptions,
  SelectInputSearch,
  SelectInputTrigger,
} from "~/shared/components/inputs/select-input";
import { TextInput } from "~/shared/components/inputs/text-input";
import { SheetRoot, SheetSheet, SheetTrigger, useCloseSheet } from "~/shared/components/sheet";
import { SheetForm } from "~/shared/components/sheet-form";

import { errorHandler } from "~/utils/error-handler";
import { timezonesOption } from "~/utils/time";

import { useRouteTenantId } from "../$tenant/route";
import { useLinkUpdateDeviceMutation, useTenantOptions } from "./hooks";

export type DeviceActionProps = {
  isEditDevice?: boolean;
  unknownDeviceSerialNumber?: string;
  deviceDetails?: Device;
  children: React.ReactNode;
};

export type DeviceFormState = {
  serialNumber?: string;
  location: string | null;
  contactPerson: string | null;
  status?: string;
  timezone: string;
  name: string;
  tenantId: string;
};

export function DeviceActionForm({
  isEditDevice = false,
  deviceDetails,
  unknownDeviceSerialNumber,
  children,
}: DeviceActionProps) {
  const { data: unknownDevices = [] } = useUnknownDevices();

  return (
    <SheetRoot>
      <SheetTrigger asChild disabled={!isEditDevice && unknownDevices.length === 0}>
        {children}
      </SheetTrigger>
      <SheetSheet>
        <DeviceSheetForm
          deviceDetails={deviceDetails}
          isEditDevice={isEditDevice}
          unknownDevices={unknownDevices}
          unknownDeviceSerialNumber={unknownDeviceSerialNumber}
        />
      </SheetSheet>
    </SheetRoot>
  );
}

function DeviceSheetForm({
  isEditDevice,
  unknownDevices,
  unknownDeviceSerialNumber,
  deviceDetails: currentDevice,
}: Omit<DeviceActionProps, "children"> & { unknownDevices: Device[] }) {
  const user = useCurrentUser();
  const tenantId = useRouteTenantId();
  const closeSheet = useCloseSheet();
  const activeDevice = useActiveDevice();

  const timezoneOptionsList = timezonesOption();

  const form = useCreateForm<DeviceFormState>({
    initialValues:
      isEditDevice && currentDevice
        ? {
            location: currentDevice.location,
            contactPerson: currentDevice.contactPerson,
            status: currentDevice.status,
            timezone: currentDevice.timezone,
            name: currentDevice.name,
            tenantId: currentDevice.tenantId,
          }
        : {
            serialNumber: unknownDeviceSerialNumber,
            location: "",
            contactPerson: "",
            name: "",
            timezone: "",
            tenantId,
          },
  });

  const { mutate: linkUpdateDeviceMutation, isPending } = useLinkUpdateDeviceMutation(
    isEditDevice || false,
    activeDevice,
    tenantId,
  );

  const { data: tenantOptions = [] } = useTenantOptions();

  const options = unknownDevices.map((d) => ({
    label: d.serialNumber,
    value: d.id,
  }));

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>, context: FormSubmitContext) => {
    event.preventDefault();

    const deviceDetails = context.values as DeviceFormState;
    linkUpdateDeviceMutation(
      {
        deviceId: (!isEditDevice ? deviceDetails.serialNumber : currentDevice?.id)!,
        deviceDetails,
      },
      {
        onSuccess: () => {
          closeSheet();
        },
        onError: (error) => {
          errorHandler(error, context);
        },
      },
    );
  };

  return (
    <SheetForm
      title={isEditDevice ? t.editDevice : t.linkDevice}
      form={form}
      onSubmit={handleSubmit}
      submitting={isPending}
    >
      <Heading level={4}>
        <FormattedMessage {...t.generalFields} />
      </Heading>

      {unknownDeviceSerialNumber ? (
        <FieldItem name="tenantId" validation={{ required: true }}>
          <FieldLabel>
            <FormattedMessage {...t.client} />
          </FieldLabel>
          <FieldInput>
            <SelectInput name="tenantId" options={tenantOptions} placeholder={t.selectClientPlaceholder}>
              <SelectInputTrigger>
                <SelectInputButton />
              </SelectInputTrigger>
              <SelectInputMenu>
                <SelectInputOptions />
              </SelectInputMenu>
            </SelectInput>
          </FieldInput>
        </FieldItem>
      ) : null}

      {!isEditDevice ? (
        <FieldItem name="serialNumber" validation={{ required: true }}>
          <FieldLabel>
            <FormattedMessage {...t.serialNumber} />
          </FieldLabel>
          <FieldInput>
            <SelectInput name="serialNumber" options={options} placeholder={t.selectDevicePlaceholder}>
              <SelectInputTrigger>
                <SelectInputButton />
              </SelectInputTrigger>
              <SelectInputMenu>
                <SelectInputOptions />
              </SelectInputMenu>
            </SelectInput>
          </FieldInput>
        </FieldItem>
      ) : null}

      <FieldItem name="name" validation={{ required: true }}>
        <FieldLabel>
          <FormattedMessage {...t.name} />
        </FieldLabel>
        <FieldInput>
          <TextInput disabled={user.isTechnician} />
        </FieldInput>
      </FieldItem>

      <FieldItem name="location">
        <FieldLabel>
          <FormattedMessage {...t.location} />
        </FieldLabel>
        <FieldInput>
          <TextInput />
        </FieldInput>
      </FieldItem>

      <FieldItem name="contactPerson">
        <FieldLabel>
          <FormattedMessage {...t.contactPerson} />
        </FieldLabel>
        <FieldInput>
          <TextInput />
        </FieldInput>
      </FieldItem>

      <Heading level={4}>
        <FormattedMessage {...t.configureFields} />
      </Heading>
      {isEditDevice ? (
        <FieldItem name="status" validation={{ required: true }}>
          <FieldLabel>
            <FormattedMessage {...t.status} />
          </FieldLabel>
          <FieldInput>
            <TextInput disabled />
          </FieldInput>
        </FieldItem>
      ) : null}

      <FieldItem name="timezone" validation={{ required: true }}>
        <FieldLabel>
          <FormattedMessage {...t.timezone} />
        </FieldLabel>
        <FieldInput>
          <SelectInput name="timezone" options={timezoneOptionsList}>
            <SelectInputTrigger>
              <SelectInputButton />
            </SelectInputTrigger>
            <SelectInputMenu>
              <SelectInputSearch />
              <SelectInputOptions />
            </SelectInputMenu>
          </SelectInput>
        </FieldInput>
      </FieldItem>
    </SheetForm>
  );
}

const t = defineMessages({
  linkDevice: {
    id: "settings_link_device_title",
    defaultMessage: "Link device",
  },
  editDevice: {
    id: "settings_edit_device_title",
    defaultMessage: "Edit device",
  },
  generalFields: {
    id: "settings_general_fields",
    defaultMessage: "General",
  },
  configureFields: {
    id: "settings_configure_fields",
    defaultMessage: "Configure",
  },
  serialNumber: {
    id: "settings_device_serial_number",
    defaultMessage: "Serial number",
  },
  client: {
    id: "settings_device_client",
    defaultMessage: "Client",
  },
  selectClientPlaceholder: {
    id: "settings_device_client_placeholder",
    defaultMessage: "Select client...",
  },
  name: {
    id: "settings_device_name",
    defaultMessage: "Name",
  },
  location: {
    id: "settings_device_location",
    defaultMessage: "Location",
  },
  contactPerson: {
    id: "settings_device_contact_person",
    defaultMessage: "Contact person",
  },
  status: {
    id: "settings_device_status",
    defaultMessage: "Status",
  },
  timezone: {
    id: "settings_device_timezone",
    defaultMessage: "Timezone",
  },
  selectDevicePlaceholder: {
    id: "settings_select_device_placeholder",
    defaultMessage: "Select device...",
  },
});
