import { useCreateForm } from "@formulier/react";

import { useLocaleOptions } from "~/components/header/fetchers";

import { defineMessages, FormattedMessage } from "~/intl";

import { Tenant } from "~/services/api-schemas";

import { FieldInput, FieldItem, FieldLabel, FormSubmitContext } from "~/shared/components/form";
import { Heading } from "~/shared/components/heading";
import {
  SelectInput,
  SelectInputButton,
  SelectInputMenu,
  SelectInputOptions,
  SelectInputSearch,
  SelectInputTrigger,
} from "~/shared/components/inputs/select-input";
import { TextInput } from "~/shared/components/inputs/text-input";
import { SheetRoot, SheetSheet, SheetTrigger, useCloseSheet } from "~/shared/components/sheet";
import { SheetForm } from "~/shared/components/sheet-form";

import { errorHandler } from "~/utils/error-handler";
import { timezonesOption } from "~/utils/time";

import { useAddTenantMutation, useUpdateTenantMutation } from "./hooks";

export type ClientFormState = {
  name: string;
  address: string | null;
  contactPerson: string | null;
  country: string;
  timezone: string;
  locale: string;
  contactPhoneNumber: string | null;
};

export type ClientActionProps = {
  tenant?: Tenant;
  isEditClient?: boolean;
  children: React.ReactNode;
};

export function ClientActionForm({ tenant, isEditClient, children }: ClientActionProps) {
  return (
    <SheetRoot>
      <SheetTrigger asChild>{children}</SheetTrigger>
      <SheetSheet>
        <ClientSheetForm tenant={tenant} isEditClient={isEditClient} />
      </SheetSheet>
    </SheetRoot>
  );
}

function ClientSheetForm({ tenant, isEditClient }: Omit<ClientActionProps, "children">) {
  const timezoneOptionsList = timezonesOption();
  const closeSheet = useCloseSheet();

  const { data: localeOptions = [] } = useLocaleOptions();

  const form = useCreateForm<ClientFormState>({
    initialValues:
      isEditClient && tenant
        ? {
            name: tenant.name,
            address: tenant.address,
            country: tenant.country,
            contactPerson: tenant.contactPerson,
            timezone: tenant.timezone,
            locale: tenant.locale,
            contactPhoneNumber: tenant.contactPhoneNumber,
          }
        : {
            name: "",
            address: "",
            contactPerson: "",
            country: "",
            timezone: "",
            locale: "",
            contactPhoneNumber: "",
          },
  });

  const { mutate: updateMutation, isPending: isUpdatePending } = useUpdateTenantMutation();
  const { mutate: addMutation, isPending: isAddPending } = useAddTenantMutation();
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>, context: FormSubmitContext) => {
    event.preventDefault();
    const tenantDetailsData = context.values as ClientFormState;

    if (isEditClient) {
      updateMutation(
        {
          tenantId: tenant?.id || "",
          tenantDetails: tenantDetailsData,
        },
        {
          onSuccess: () => {
            closeSheet();
          },
          onError: (error) => {
            errorHandler(error, context);
          },
        },
      );
    } else {
      addMutation(
        { tenantDetails: tenantDetailsData },
        {
          onSuccess: () => {
            closeSheet();
          },
          onError: (error) => {
            errorHandler(error, context);
          },
        },
      );
    }
  };

  return (
    <SheetForm
      title={isEditClient ? t.editClient : t.addClient}
      form={form}
      onSubmit={handleSubmit}
      submitting={isEditClient ? isUpdatePending : isAddPending}
    >
      <Heading level={4}>
        <FormattedMessage {...t.generalFields} />
      </Heading>

      <FieldItem name="name" validation={{ required: true }}>
        <FieldLabel>
          <FormattedMessage {...t.name} />
        </FieldLabel>
        <FieldInput>
          <TextInput />
        </FieldInput>
      </FieldItem>

      <FieldItem name="address">
        <FieldLabel>
          <FormattedMessage {...t.address} />
        </FieldLabel>
        <FieldInput>
          <TextInput />
        </FieldInput>
      </FieldItem>

      <FieldItem name="country" validation={{ required: true }}>
        <FieldLabel>
          <FormattedMessage {...t.country} />
        </FieldLabel>
        <FieldInput>
          <TextInput />
        </FieldInput>
      </FieldItem>

      <FieldItem name="contactPerson">
        <FieldLabel>
          <FormattedMessage {...t.contactPerson} />
        </FieldLabel>
        <FieldInput>
          <TextInput />
        </FieldInput>
      </FieldItem>

      <FieldItem name="contactPhoneNumber">
        <FieldLabel>
          <FormattedMessage {...t.contactPhoneNumber} />
        </FieldLabel>
        <FieldInput>
          <TextInput placeholder="000-2222-000" />
        </FieldInput>
      </FieldItem>

      <Heading level={4}>
        <FormattedMessage {...t.configureFields} />
      </Heading>

      <FieldItem name="locale" validation={{ required: true }}>
        <FieldLabel>
          <FormattedMessage {...t.language} />
        </FieldLabel>
        <FieldInput>
          <SelectInput name="locale" options={localeOptions}>
            <SelectInputTrigger>
              <SelectInputButton />
            </SelectInputTrigger>
            <SelectInputMenu>
              <SelectInputSearch />
              <SelectInputOptions />
            </SelectInputMenu>
          </SelectInput>
        </FieldInput>
      </FieldItem>

      <FieldItem name="timezone" validation={{ required: true }}>
        <FieldLabel>
          <FormattedMessage {...t.timezone} />
        </FieldLabel>
        <FieldInput>
          <SelectInput name="timezone" options={timezoneOptionsList}>
            <SelectInputTrigger>
              <SelectInputButton />
            </SelectInputTrigger>
            <SelectInputMenu>
              <SelectInputSearch />
              <SelectInputOptions />
            </SelectInputMenu>
          </SelectInput>
        </FieldInput>
      </FieldItem>
    </SheetForm>
  );
}
const t = defineMessages({
  editClient: {
    id: "settings_edit_client_title",
    defaultMessage: "Edit client",
  },
  addClient: {
    id: "settings_add_client_title",
    defaultMessage: "Add client",
  },
  name: {
    id: "settings_edit_client_name",
    defaultMessage: "Name",
  },
  address: {
    id: "settings_edit_client_address",
    defaultMessage: "Address",
  },
  contactPerson: {
    id: "settings_edit_client_contact_person",
    defaultMessage: "Contact person",
  },
  contactPhoneNumber: {
    id: "settings_edit_client_contact_phone_number",
    defaultMessage: "Contact phone number",
  },
  country: {
    id: "settings_edit_client_country",
    defaultMessage: "Country",
  },
  language: {
    id: "settings_edit_client_langauge",
    defaultMessage: "Language",
  },
  timezone: {
    id: "settings_edit_client_timezone",
    defaultMessage: "Timezone",
  },
  generalFields: {
    id: "settings_edit_client_general_fields",
    defaultMessage: "General",
  },
  configureFields: {
    id: "settings_edit_client_configure_fields",
    defaultMessage: "Configure",
  },
});
